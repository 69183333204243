import React from "react";
import { CommonHeader } from "header-component";
import "./index.css"
const CustomHeader = () => {
  return (
    <>
    <div className="desktop">
     <CommonHeader />
    </div>
      <div className="mobile">
      <CommonHeader IconColor= "black"/>
     </div>
     </>
  );
};
export default CustomHeader;
