import React, { lazy, Suspense, useState, useEffect } from "react";
import "../src/App.css";
import Header from "./components/Header";
import CacheBuster from 'react-cache-buster';
import appversion from '../package.json';
import { CookieSetting } from "cookiesetting-component";
const Body = lazy(() => import("./components/Body/Body"));
 

const App = () => {
  const [showWidgets, setShowWidgets] = useState(false);
  const appVersion = appversion.version;
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWidgets(true); // Load AnalyticsAndWidgets after a delay
    }, 3000); // Adjust delay as needed (3000ms = 3 seconds)

    return () => clearTimeout(timer); // Cleanup timer
  }, []);

  return (
   
    <div className="bodyContainer">
      <Header />
      <Suspense fallback={<div />}>
        <Body />
      </Suspense>
    
      <CookieSetting
        privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK}
        configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
        analytics={process.env.REACT_APP_ANALYTICS_PATH}
      />
    </div>
 
  );
};

export default App;
